import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, watch } from 'vue';
import AppLayout from '@/components/layouts/AppLayout/AppLayout.vue';
import {
  HttpStatus,
  Locale,
  RouteAuthenticationRequirement,
} from '@/shared/types/generic';
import useUserStore from '@/store/user/useUserStore';
import useTokenStore from '@/store/token/useTokenStore';
import { isEmpty } from '@/shared/helpers/validators/validators';
import {
  formatDateToString,
  updateCommonsLocale,
} from '@vaibe-github-enterprise/kd-kse-fe-commons';
import { useRoute, useRouter } from 'vue-router';
import useProfileStore from '@/store/profile/useProfileStore';
import { useI18n } from 'vue-i18n';
import useOrganizationsStore from './store/organizations/useOrganizationsStore';
import useErrorStore from './store/error/useErrorStore';
import useDynamicTitle from './composables/useDynamicTitle/useDynamicTitle';
import i18n from './plugins/i18n';
import { BaseLoaderList } from './plugins/commons';
import notify from './shared/helpers/notify/notify';
import useLogout from './composables/useLogOut/useLogOut';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { updateTitle } = useDynamicTitle();

const profileStore = useProfileStore();
const tokenStore = useTokenStore();
const userStore = useUserStore();
const organizationsStore = useOrganizationsStore();
const errorStore = useErrorStore();

const router = useRouter();
const route = useRoute();

const { t } = useI18n();

const { logOut } = useLogout();

const requiresAuthentication = computed<boolean>(() => {
  return route.matched.some(
    (match) =>
      match.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );
});

watch(
  () => tokenStore.token,
  (newToken?: string) => {
    if (isEmpty(newToken)) {
      userStore.resetStore();

      profileStore.resetStore();

      organizationsStore.$reset();

      i18n.global.locale.value = process.env.VUE_APP_I18N_LOCALE || Locale.US;
      updateCommonsLocale(process.env.VUE_APP_I18N_LOCALE || Locale.US);
    }
  },
  { immediate: true },
);

function warnUser(): void {
  if (!isEmpty(errorStore.lockedErrorMessage)) {
    notify(
      t(`errors.reason.${errorStore.lockedErrorMessage?.code}`, [
        formatDateToString(
          errorStore.lockedErrorMessage?.lockedOutEndTime || 0,
          t('common.format.input.maskTime'),
        ),
      ]),
      'danger',
    );
  }
}

watch(
  () => errorStore.errorCode,
  async (newErrorCode?: HttpStatus) => {
    switch (newErrorCode) {
      case HttpStatus.FORBIDDEN:
      case HttpStatus.INACTIVE_USER:
      case HttpStatus.UNAUTHORIZED_USER:
      case HttpStatus.EXPIRED_PASSWORD:
        tokenStore.setToken('', '', '');
        router.push(`/login`);
        break;
      case HttpStatus.LOCKED_USER:
        warnUser();
        await logOut();
        break;
      case HttpStatus.SERVER_MAINTENANCE:
      case HttpStatus.NO_CONNECTION:
        router.push({
          path: `/${newErrorCode}`,
          query: { bypass: 'true' },
        });
        break;
      default:
        break;
    }
  },
  { immediate: true },
);

watch(
  () => profileStore.userProfile?.userConfig.language,
  (newUserProfileLanguage) => {
    i18n.global.locale.value = newUserProfileLanguage || Locale.US;
    updateCommonsLocale(newUserProfileLanguage || Locale.US);
  },
  { immediate: true },
);

watch(
  () => i18n.global.locale.value,
  () => {
    updateTitle(router.currentRoute.value.name);
  },
  { immediate: true },
);

watch(
  () => route.matched,
  (newVal) => {
    const routes = newVal.filter((match) => {
      return !match.meta.firstLevel && match.name;
    });

    const routeName = String(routes[routes.length - 1].name);

    updateTitle(routeName);
  },
  { immediate: true },
);

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (requiresAuthentication.value)
    ? (_openBlock(), _createBlock(AppLayout, { key: 0 }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view),
          _createVNode(_unref(BaseLoaderList))
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_router_view, { key: 1 }))
}
}

})