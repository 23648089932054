import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "19",
  height: "19",
  viewBox: "0 0 24 24"
}
const _hoisted_2 = ["fill"]

import { GRAY_700 } from '@/shared/constants/colors';
import { IconSettings } from '@/shared/types/components';


export default /*@__PURE__*/_defineComponent({
  __name: 'icon-wrench-outline',
  props: {
    color: { default: GRAY_700 },
    colorSecondary: {},
    colorTertiary: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      fill: props.color,
      d: "m22.61 19l-9.08-9.09c.93-2.34.47-5.1-1.44-7C9.79.61 6.21.4 3.66 2.26L7.5 6.11L6.08 7.5L2.25 3.69C.39 6.23.6 9.82 2.9 12.11c1.86 1.86 4.57 2.35 6.89 1.48l9.11 9.11c.39.39 1.02.39 1.41 0l2.3-2.3c.39-.4.39-1.01 0-1.4m-3 1.59l-9.46-9.46c-.61.45-1.29.72-2 .82c-1.36.2-2.79-.21-3.83-1.25C3.37 9.76 2.93 8.5 3 7.26l3.09 3.09l4.24-4.24L7.24 3c1.26-.05 2.49.39 3.44 1.33a4.47 4.47 0 0 1 1.24 3.96a4.35 4.35 0 0 1-.88 1.96l9.46 9.45z"
    }, null, 8, _hoisted_2)
  ]))
}
}

})