import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "48",
  height: "49",
  viewBox: "0 0 48 49",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["clip-path"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]

import { v4 } from 'uuid';


export default /*@__PURE__*/_defineComponent({
  __name: 'icon-vaibe-logo-small-filled',
  setup(__props) {

const uuid = v4();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("g", {
      "clip-path": `url(#${_unref(uuid)}_clip0_7484_121134)`
    }, [
      _createElementVNode("path", {
        d: "M7.03073 41.3382C16.4038 50.7113 31.5979 50.7097 40.9695 41.3382C50.3426 31.9651 50.3442 16.771 40.9695 7.39945C31.5963 -1.97366 16.4006 -1.97526 7.02753 7.39945C-2.34559 16.7726 -2.34399 31.9683 7.02753 41.3414L7.03073 41.3382Z",
        fill: `url(#${_unref(uuid)}_paint0_linear_7484_121134)`
      }, null, 8, _hoisted_3),
      _cache[0] || (_cache[0] = _createElementVNode("path", {
        d: "M20.9158 24.2256L14.9332 13.8652C14.0084 12.2636 11.9603 11.7164 10.3602 12.6396C8.75858 13.5644 8.21136 15.6109 9.13459 17.2125L15.1172 27.5729L21.0982 37.9332C21.7174 39.0069 22.8438 39.6069 24.0007 39.6069C24.5687 39.6069 25.1447 39.4629 25.6711 39.1589C27.2728 38.2341 27.82 36.1876 26.8968 34.5859L20.9158 24.2256Z",
        fill: "white"
      }, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("path", {
        d: "M36.6684 12.2667C36.4364 12.2187 36.1996 12.1963 35.9628 12.1899H23.9992C22.1511 12.1899 20.6519 13.6892 20.6519 15.5373C20.6519 17.3853 22.1511 18.8846 23.9992 18.8846H30.1642L27.0809 24.2239C25.772 26.3904 27.4649 29.2945 29.977 29.2449C31.1338 29.2449 32.2586 28.6449 32.8795 27.5713L38.8621 17.2109C38.9197 17.1021 38.9757 16.9917 39.0285 16.8797C39.8925 15.0076 38.6925 12.6636 36.67 12.2667H36.6684Z",
        fill: "white"
      }, null, -1))
    ], 8, _hoisted_2),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", {
        id: `${_unref(uuid)}_paint0_linear_7484_121134`,
        x1: "47.9873",
        y1: "0.378421",
        x2: "-0.563127",
        y2: "48.9288",
        gradientUnits: "userSpaceOnUse"
      }, _cache[2] || (_cache[2] = [
        _createElementVNode("stop", {
          offset: "0.08",
          "stop-color": "#982AA2"
        }, null, -1),
        _createElementVNode("stop", {
          offset: "0.93",
          "stop-color": "#1659F2"
        }, null, -1)
      ]), 8, _hoisted_4),
      _createElementVNode("clipPath", {
        id: `${_unref(uuid)}_clip0_7484_121134`
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("rect", {
          width: "48",
          height: "48",
          fill: "white",
          transform: "translate(0 0.367188)"
        }, null, -1)
      ]), 8, _hoisted_5)
    ])
  ]))
}
}

})