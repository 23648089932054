<template>
  <q-layout view="lHh lpR fFf">
    <AppLayoutDrawer v-model="isDrawerOpen" :mini="miniState" />
    <q-page-container class="app-layout-page__content">
      <slot />
    </q-page-container>
  </q-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import AppLayoutDrawer from '@/components/app/AppLayoutDrawer/AppLayoutDrawer.vue';
import { useRoute } from 'vue-router';
import { useQuasar } from 'quasar';

export default defineComponent({
  name: 'AppLayout',

  components: { AppLayoutDrawer },

  setup() {
    const { meta } = useRoute();

    const { screen } = useQuasar();

    return { meta, screen };
  },

  data() {
    return {
      isDrawerOpen: true,
    };
  },

  computed: {
    miniState(): boolean {
      return (
        ['xs', 'sm'].includes(this.screen.name) || this.meta.miniMode === true
      );
    },
  },
});
</script>
<style scoped lang="scss">
.app-layout-page__content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  max-height: 100vh;
}

.app-layout-page__content > :deep(main) {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
</style>
