import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import IconGear from '@/assets/icons/icon-gear.vue';
import AppDrawerLink from '@/components/app/AppDrawerLink/AppDrawerLink.vue';
import { useI18n } from 'vue-i18n';

interface Props {
  mini: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganizationSettingsLink',
  props: {
    mini: { type: Boolean }
  },
  setup(__props: any) {



const { t } = useI18n();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppDrawerLink, {
    to: "/organization-settings/details",
    mini: _ctx.mini,
    label: _unref(t)('nav.organizationSettings')
  }, {
    leading: _withCtx(() => [
      _createVNode(IconGear)
    ]),
    _: 1
  }, 8, ["mini", "label"]))
}
}

})