import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "profile-avatar" }
const _hoisted_2 = { class: "profile-avatar__label" }

import { GRAY_50 } from '@/shared/constants/colors';

interface Props {
  nameInitials: string;
  size?: string;
  labelFontSize?: string;
  backgroundColor?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileAvatar',
  props: {
    nameInitials: { default: '' },
    size: { default: '104px' },
    labelFontSize: { default: '24px' },
    backgroundColor: { default: GRAY_50 }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "1c33bbb6": (_ctx.size),
  "29ad2800": (_ctx.backgroundColor),
  "2cf5cfef": (_ctx.labelFontSize)
}))



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.nameInitials), 1)
  ]))
}
}

})