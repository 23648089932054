import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import Profile from '@/shared/classes/profile';
import AppDrawerLink from '@/components/app/AppDrawerLink/AppDrawerLink.vue';
import useProfileStore from '@/store/profile/useProfileStore';
import ProfileAvatar from '../ProfileAvatar/ProfileAvatar.vue';

interface Props {
  mini: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileDrawerLink',
  props: {
    mini: { type: Boolean }
  },
  setup(__props: any) {



const profileStore = useProfileStore();

const profileInstance = computed(
  () => profileStore.userProfile && new Profile(profileStore.userProfile),
);

return (_ctx: any,_cache: any) => {
  return (profileInstance.value)
    ? (_openBlock(), _createBlock(AppDrawerLink, {
        key: 0,
        to: "/account-settings",
        class: "profile-drawer-link",
        mini: _ctx.mini,
        label: profileInstance.value.user.name
      }, {
        leading: _withCtx(() => [
          _createVNode(ProfileAvatar, {
            size: "24px",
            "label-font-size": "10px",
            "background-color": "white",
            "name-initials": profileInstance.value.getNameInitials()
          }, null, 8, ["name-initials"])
        ]),
        _: 1
      }, 8, ["mini", "label"]))
    : _createCommentVNode("", true)
}
}

})